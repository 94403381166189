import React from 'react'
import { Navbar, Container } from 'react-bootstrap'
import './header.scss'
import { logo } from '../../../assets'

const Header = () => {
  return (
    <div>
        <Navbar className='bg-header'>
            <Container>
                <Navbar.Brand href="/" className='bg-logo'>
                  <img className="bg-logo" src={ logo }/>
                </Navbar.Brand>
            </Container>
        </Navbar>
    </div>
  )
}

export default Header
