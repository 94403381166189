import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import './home.scss'
import { imgTop, whatsapp } from '../../../assets'
import { HiOutlineDocumentSearch, HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { RiBankLine } from "react-icons/ri";
import { FaRegFileAudio } from "react-icons/fa";
import ReactPlayer from 'react-player'

// import ReactPlayer from 'react-player'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const Home = () => {
  return (
    <div className=''>
      <div className='box-margin-top top bg-top'>
        <div className='content-wrapper'>
          <Container> 
            <Row>
              <Col md>
                <img className="img-top" src={ imgTop } alt="First slide"/>
              </Col>
              <Col md>
                <div className='box-title'>
                  <div className='box-box-title'>
                    <h1 className='teks-bold'>E-SIDISA</h1>
                    <h4 className='teks-bold'>Elektronik Sistem Informasi Disabilitas</h4>
                    <p className='justify-text-box-title'>Pengadilan Agama Lubuk Pakam terus berkomitmen untuk selalu memberikan pelayanan prima kepada masyarakat, termasuk penyandang disabilitas. Kini, kami menghadirkan website yang ramah bagi disabilitas.</p>
                  </div> 
                </div>          
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className='box-margin top bg'>
        <div className='content-wrapper'>
          <h3 className='teks-bold center-text'>KENALIN E-SIDISA</h3>
          <div className='box-intro'>
            <p className='justify-text'>E-SIDISA menyediakan beberapa fitur tentang informasi pelayanan bagi penyandang disabilitas yang terdapat di dalam Pengadilan Agama Lubuk Pakam, terdiri dari S.O.P alur layanan berperkara bagi penyandang disabilitas, tutorial pembuatan gugatan mandiri, whatsapp auto respon sebagai sistem informasi digital layanan berperkara dan Survey Penilaian Masyarakat. Dengan adanya E-SIDISA maka akan memudahkan penyandang disabilitas dalam mengakses informasi melalui website Pengadilan Agama Lubuk Pakam.</p>
          </div>
        </div>
      </div>

      <div className='box-margin light-grey'>
        <div className='content-wrapper'>
          <Container>
            <Row>
              <Col xs={5} md={2} lg={2} className='margin-box'>
                <a href="https://drive.google.com/file/d/1exoolXrw9gt1EhiWfRN9K7MVllJnCsVv/view" className='a-href' target="_blank">
                <div className='box-items'>
                  <div className='circle manualbookstyle'>
                    <HiOutlineDocumentSearch className='icon-circle'/>
                  </div>
                  <p className='text-p'>Manual Book E-SIDISA</p>
                </div>
                </a>
              </Col>
              <Col xs={5} md={2} lg={2} className='margin-box'>
                <a href="https://drive.google.com/file/d/1nOLsEN5AiLJTitnRFxeUlNYj1GgDEJYb/view" className='a-href' target="_blank">
                <div className='box-items'>
                  <div className='circle walkmanstyle'>
                    <HiOutlineDocumentSearch className='icon-circle'/>
                  </div>
                  <p className='text-p'>Penggunaan Audio Walkman</p>
                </div>
                </a>
              </Col>
              <Col xs={5} md={2} lg={2} className='margin-box'>
                <a href="https://drive.google.com/file/d/1PCC0yd5rQZ6oUS6ZbzjnebELaQivj5GN/view" className='a-href'>
                <div className='box-items'>
                  <div className='circle'>
                    <HiOutlineDocumentSearch className='icon-circle'/>
                  </div>
                  <p className='text-p'>SOP Disabilitas</p>
                </div>
                </a>
              </Col>
              <Col xs={5} md={2} lg={2} className='margin-box'>
                <a href="https://heyzine.com/flip-book/72663fa49f.html" className='a-href'>
                <div className='box-items'>
                  <div className='circle'>
                    <RiBankLine className='icon-circle'/>
                  </div>
                  <p className='text-p'>Sarana dan Prasarana Disabilitas</p>
                </div>
                </a>
              </Col>
              <Col xs={5} md={2} lg={2} className='margin-box'>
                <a href="https://heyzine.com/flip-book/c051eca2be.html" className='a-href'>
                <div className='box-items'>
                  <div className='circle'>
                    <FaRegFileAudio className='icon-circle'/>
                  </div>
                  <p className='text-p'>Panduan Gugatan Mandiri</p>
                </div>
                </a>
              </Col>
              <Col xs={5} md={2} lg={2} className='margin-box'>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdo_c-oewBnCvyfyku9nUNdLl_fdP6d-i88obIJE2MTQKHKyA/viewform?usp=sf_link" className='a-href'>
                <div className='box-items'>
                  <div className='circle'>
                    <HiOutlineDocumentDuplicate className='icon-circle'/>
                  </div>
                  <p className='text-p'>Survey Penilaian Masyarakat</p>
                </div>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <div className='box-video'>
        <div className='content-wrapper'>
          <Carousel responsive={responsive}>
            <div className='box-item-video'>
              <ReactPlayer width="100%" height="210px" url='https://www.youtube.com/embed/jVgMJrYmdQE' />
            </div>
            <div className='box-item-video'>
              <ReactPlayer width="100%" height="210px" url='https://www.youtube.com/embed/QYp8RDdA7RE' />
            </div>
            <div className='box-item-video'>
              <ReactPlayer width="100%" height="210px" url='https://www.youtube.com/embed/L1S1tbDCyCw' />
            </div>
            <div className='box-item-video'>
              <ReactPlayer width="100%" height="210px" url='https://www.youtube.com/embed/klw9ZuEtaro' />
            </div>
            <div className='box-item-video'>
              <ReactPlayer width="100%" height="210px" url='https://www.youtube.com/embed/_Zjg_kI1UoM' />
            </div>
            <div className='box-item-video'>
              <ReactPlayer width="100%" height="210px" url='https://www.youtube.com/embed/0riycyeh_PE' />
            </div>
          </Carousel>
        </div>
      </div>

      <a href="https://api.whatsapp.com/send?phone=6281376112258&text=Hi%20LARA.%20Saya%20butuh%20bantuan%20Anda" className='tombol_contact_us'>
        <img className="whatsapp" src={ whatsapp }/><p className='text-whatsapp'>Hubungi Kami (SI LARA)</p>
      </a>

      <div className='box-audio-player'>
        <div className='content-wrapper'>
          <h3 className='teks-bold center-text'>Fasilitas Disabilitas Pengadilan Agama</h3>
          <h3 className='teks-bold center-text top-min'>Lubuk Pakam</h3>
        </div>
      </div>

      <div className='fp-player'>
        <div className='content-wrapper'>
          <iframe width="100%" height="500" title="iframe-audio-book" class="fp-iframe" src=" https://heyzine.com/flip-book/72663fa49f.html" allowfullscreen></iframe>
        </div>
      </div>

      
      <div className='box-margin'>
        <div className='box-maps content-wrapper'>
          <Container> 
            <Row>
              <Col md>
                <div className='box-maps-teks'>
                  <h1 className='teks-bold'>Pengadilan Agama</h1>
                  <h1 className='teks-bold'>Lubuk Pakam</h1>
                  <p className=''>Jl. Mahoni No.3, Komplek Perkantoran Kabupaten Deli Serdang, Lubuk Pakam, Sumatra Utara</p>
                </div>           
              </Col>
              <Col md>
                <div className='box-box-maps'>
                  <iframe className='box-box-maps' width="350" height="300" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3982.165475227849!2d98.86728031454332!3d3.549293997417026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a59b5543bbedb%3A0x6e182ccab13fb60a!2sPengadilan%20Agama%20Lubuk%20Pakam%20Kelas%201%20A!5e0!3m2!1sid!2sid!4v1665973125080!5m2!1sid!2sid" allowFullScreen></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Home
