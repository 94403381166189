import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.scss'
import { NavLink } from 'react-router-dom';
import { FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare } from 'react-icons/fa';
import { logoWhite } from '../../../assets';

const Footer = () => {
    return (
        <div>
            <div className="footer">
                <Container>
                    <Row>
                        <Col sm={4}>
                            <div className="box-logo">
                                <img className="bg-logo-footer" src={ logoWhite }/>
                                <p className="t-sub-logo">Pengadilan Agama Lubuk Pakam terus berkomitmen untuk selalu memberikan pelayanan prima kepada masyarakat, termasuk penyandang disabilitas</p>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="box-support">
                                <div className="t-support">Support</div>
                                <ul className="t-sub-support t-link-support" type="none">
                                    <li><NavLink to="/login" className="t-link-support">About us</NavLink></li>
                                    <li><NavLink to="/login" className="t-link-support">Terms and Conditions</NavLink></li>
                                    <li><NavLink to="/login" className="t-link-support">Privacy Policy</NavLink></li>
                                    <li><NavLink to="/login" className="t-link-support">FAQ</NavLink></li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="box-sosial-media">
                                <div className="t-sosial-media">Contact us</div>
                                <div className="box-icon">
                                    <p className="t-sub-sosial-media">Jl. Mahoni No.3, Komplek Perkantoran Kabupaten Deli Serdang, Lubuk Pakam, Deli Serdang, Sumatra Utara 20514</p>
                                    <p className="t-link-support">Call Center</p>
                                    <p className="t-no">Fax. (061) 7956225</p>
                                    <p className="t-no">Telp. (061) 7952010</p>
                                    
                                    <span><NavLink to="/login" className="sub-icon-ms"><FaFacebookSquare /></NavLink></span>
                                    <span><NavLink to="/login" className="sub-icon-ms"><FaInstagramSquare /></NavLink></span>
                                    <span><NavLink to="/login" className="sub-icon-ms"><FaWhatsappSquare /></NavLink></span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="box-copyright">
                                <div className="t-copyright">
                                    <div>© 2022 Pengadilan Agama Lubuk Pakam Kelas I A</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Footer
