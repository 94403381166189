import { Routes } from '../config';
import './App.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
